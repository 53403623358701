<template>
    <div class="list-view">
        <page-title>
            <el-button
                @click="download"
                icon="el-icon-upload2"
                :loading="downloading"
                :disabled="fetchingData"
                class="filter-item"
            >
                导出
            </el-button>
            <el-button
                type="primary"
                @click="downloadCheckData"
                icon="el-icon-upload2"
                :loading="downloading"
                :disabled="fetchingData"
                class="filter-item"
                title="通过带'*'的字段筛选"
            >
                导出核对分账数据
            </el-button>
            <el-button
                type="warning"
                @click="downloadSandpayDiv"
                icon="el-icon-upload2"
                :loading="downloading"
                :disabled="fetchingData"
                class="filter-item"
                title="通过带'*'的字段筛选"
            >
                导出杉德批量转账数据
            </el-button>
            <el-popover
            class="filter-item"
            placement="top"
            width="160"
            style="margin-left:10px;"
            v-model="balancePopVisible">
                <p>选择一个需要查询的支付平台</p>
                <el-select  v-model="balancePayPlatform"  :clearable="true"  >
                    <el-option v-for="(value,key) in payPlatformMap" :label="value"  :value="key" :key="key"></el-option>
                </el-select>
                <div style="text-align: right; margin-top:8px;">
                    <el-button type="primary" size="mini" @click="queryBalance">查询</el-button>
                </div>
            
                <el-button
                    slot="reference"
                    type="success"
                    icon="el-icon-search"
                    :loading="downloading"
                    :disabled="fetchingData"
                >
                    余额查询
                </el-button>
            </el-popover>


        </page-title>
        <div class="filters-container">
            <el-form :model="formData" :inline="true">
                <el-form-item label="创建时间*">
                    <created-at-picker v-model="formData.createdAt" @input="initData" name="创建"></created-at-picker>
                </el-form-item>
                <el-form-item label="支付时间*">
                    <created-at-picker v-model="formData.payTime" @input="initData" name="支付"></created-at-picker>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input v-model="formData.userId"       placeholder="用户ID" clearable=""  ></el-input>
                </el-form-item>
               
                <el-form-item label="购买订单ID">
                    <el-input v-model="formData.orderId"       placeholder="购买订单ID" clearable=""  ></el-input>
                </el-form-item>

                <el-form-item label="分账状态*">                    
                    <el-select  v-model="formData.status"  :clearable="true"  >
                        <el-option v-for="(value,key) in divStatusMap" :label="value"  :value="key" :key="key"></el-option>
                    </el-select>
                </el-form-item>

                 <el-form-item label="支付平台*">                    
                    <el-select  v-model="formData.payPlatform"  :clearable="true"  >
                        <el-option v-for="(value,key) in payPlatformMap" :label="value"  :value="key" :key="key"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="分类开关">    
                    <el-switch
                    v-model="formData.showUserData"
                    active-text="仅用户"
                    inactive-text="全部"
                    @change="initData"
                    >
                    </el-switch>
                </el-form-item>
            </el-form>
            
            <div class="tool-wrapper">
                <div class="tool-right">
                     <el-button
                    type="success"
                    @click="markStatus('PROCESSED')"
                    :disabled="fetchingData"
                    class="filter-item"
                >
                    批量标记为已处理
                </el-button>
                <el-button
                    type="danger"
                    @click="markStatus('ERROR')"
                    :disabled="fetchingData"
                    class="filter-item"
                >
                    批量标记为处理失败
                </el-button>
                <el-button
                    type="danger"
                    @click="batchDivMoney()"
                    :disabled="fetchingData"
                    class="filter-item"
                >
                    执行批量分账
                </el-button>
                <el-tag>订单金额({{moneyInfo.orderAmount || 0}}￥)</el-tag>    
                &nbsp;            
                <el-tag type="success">分账总金额({{moneyInfo.totalAmount || 0}}￥)</el-tag>
                &nbsp;
                <el-tag type="warning">实际分账金额({{moneyInfo.amount || 0}}￥)</el-tag>
                &nbsp;
                <el-tag type="danger">退款金额({{moneyInfo.refundAmount || 0}}￥)</el-tag>
                </div>
                <el-input
                    placeholder="搜索..."
                    v-model="search"
                    clearable
                    class="filter-item search"
                    @keyup.enter.native="initData"
                >
                    <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
                </el-input>
            </div>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            :height="tableHeight"
            v-loading="fetchingData"
            @selection-change="handleSelectionChange"
        >
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="userId" label="用户ID" > </el-table-column>
            <el-table-column prop="orderId" label="购买订单ID"   min-width="140" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="orderAmount" label="订单金额" min-width="100"  show-overflow-tooltip> </el-table-column>
            <el-table-column prop="totalAmount" label="分账总金额" min-width="100"  show-overflow-tooltip> </el-table-column>
            <el-table-column prop="amount" label="实际分账金额" min-width="100"  show-overflow-tooltip> </el-table-column>
            <el-table-column prop="ratio" label="分账比例"  min-width="100"  show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="scope.row.ratio">{{scope.row.ratio}}%</span>
                </template> 
            </el-table-column>
            <el-table-column prop="restAmount" label="剩余金额" > </el-table-column>
            <el-table-column prop="feeFlag" label="承担手续费" >
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.feeFlag"  type="success">是</el-tag>
                    <el-tag v-else  type="danger">否</el-tag>
                </template> 
            </el-table-column>
            <el-table-column prop="status" label="状态" > 
                <template slot-scope="scope">
                    <el-tooltip placement="top" :content="scope.row.exportTips || '没有问题'">
                        <el-tag :type="divStatusMap[scope.row.status].type" v-if="divStatusMap[scope.row.status]">{{divStatusMap[scope.row.status]}}</el-tag>
                    </el-tooltip>
                </template> 
            </el-table-column>
            <el-table-column prop="payPlatform" label="支付平台" min-width="100"> 
                <template slot-scope="scope">
                    <el-tag :type="payPlatformMap[scope.row.payPlatform].type" v-if="payPlatformMap[scope.row.payPlatform]">{{payPlatformMap[scope.row.payPlatform]}}</el-tag>
                </template> 
            </el-table-column>
             <el-table-column prop="createdAt" label="创建时间" width="150">
                <template slot="header" slot-scope="{ column }">
                    <sortable-header :column="column" :current-sort="sort" @changeSort="changeSort"> </sortable-header>
                </template>
            </el-table-column>
            <el-table-column prop="exportPayTime" label="支付时间" width="150">
            </el-table-column>
             <el-table-column label="操作" min-width="140">
                <template slot-scope="scope">
                    <el-button type="danger" size="mini"  @click="executeDivMoney(scope.row.id)" 
                    v-if="scope.row.userId != null && scope.row.status != 'PROCESSED' && scope.row.transferMoneyId == null"
                    :disabled="fetchingData"
                    >执行分账</el-button>
                    <el-button type="primary" size="mini"  @click="viewTransferInfo(scope.row.transferMoneyId)" 
                    v-if="scope.row.transferMoneyId != null">查看转账记录</el-button>
                </template>
            </el-table-column>

        </el-table>
        <div class="pagination-wrapper">
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>

        <el-dialog title="查看转账记录"  :visible.sync="transferInfoDialog">
            <div class="desc-wrapper">
                <div class="desc-line">
                    <div class="desc-label">交易编号</div>
                    <div class="desc-content">{{transferInfoData.tradeNo}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">目标用户ID</div>
                    <div class="desc-content">{{transferInfoData.toUserId}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">转账方式</div>
                    <div class="desc-content">{{transferInfoData.transferMethod}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">账户类型</div>
                    <div class="desc-content">{{transferInfoData.accountType}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">账户号</div>
                    <div class="desc-content">{{transferInfoData.accountNo}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">账户名称</div>
                    <div class="desc-content">{{transferInfoData.accountName}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">交易金额</div>
                    <div class="desc-content">{{transferInfoData.amount}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">转账订单ID</div>
                    <div class="desc-content">{{transferInfoData.transferOrderId}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">交易流水ID</div>
                    <div class="desc-content">{{transferInfoData.transactionId}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">转账时间</div>
                    <div class="desc-content">{{transferInfoData.transferDate}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">转账状态</div>
                    <div class="desc-content">{{transferInfoData.status}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">转账结果</div>
                    <div class="desc-content">{{transferInfoData.result}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">第三方账户ID</div>
                    <div class="desc-content">{{transferInfoData.thirdAccountId}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">创建时间</div>
                    <div class="desc-content">{{transferInfoData.createdAt}}</div>
                </div>
                <div class="desc-line">
                    <div class="desc-label">转账理由</div>
                    <div class="desc-content">{{transferInfoData.reason}}</div>
                </div>
            </div>
            <div slot="footer">
                <el-button type="danger"  v-if="transferInfoData.status == 'FAILED' "
                 @click="retryTransfer(transferInfoData.divMoneyId)"
                :disabled="fetchingData"
                >重试交易</el-button>
                <el-button type="primary" @click="transferInfoDialog = false;">关闭</el-button>
                
            </div>
        </el-dialog>

    </div>
</template>
<script>
import pageableTable from '@/mixins/pageableTable';
import SortableHeader from '../components/SortableHeader.vue';
import {divStatusMap,payPlatformMap} from "@/utils/constant.js";

export default {
    components: { SortableHeader },
    name: 'divMoneyList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: true,
            search: '',
            url: '/divMoney/all',
            downloading: false,
            formData:{
                 createdAt: "",
                 payTime:"",
                 userId:"",
                 orderId:"",
                 status: null,
                 payPlatform:"",
                 showUserData:false,
                 
            },
            divStatusMap,
            payPlatformMap,
            multipleSelection:[],
            moneyInfo:{},
            transferInfoDialog: false,
            transferInfoData: {},
            balancePopVisible: false,
            balancePayPlatform: ""
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        beforeGetData() {
            return {
                search: this.search,
                query: { del: false, ...this.formData}
            };
        },
        afterGetData(){
            //计算金额信息
            this.calculateMoneyInfo();
        },
        download() {
            this.downloading = true;
            let reqData = this.beforeGetData();
            reqData.size = 10000;
            this.$axios
                .request( {
                    method:"post",
                    url: '/divMoney/excel',
                    responseType: 'blob',
                    data: reqData
                })
                .then(res => {
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', decodeURIComponent(res.headers['content-disposition'].split('filename=')[1]));
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        downloadCheckData(){
             this.downloading = true;
             let reqData = this.beforeGetData();
             this.$axios
                .request({
                    method:"post",
                    url: '/divMoney/checkDataExcel',
                    responseType: 'blob',
                    data: reqData
                })
                .then(res => {
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', decodeURIComponent(res.headers['content-disposition'].split('filename=')[1]));
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        downloadSandpayDiv(){
            this.downloading = true;
             let reqData = this.beforeGetData();
             this.$axios
                .request({
                    method:"post",
                    url: '/divMoney/exportSandpayDiv',
                    responseType: 'blob',
                    data: reqData
                })
                .then(res => {
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', decodeURIComponent(res.headers['content-disposition'].split('filename=')[1]));
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        handleSelectionChange(val){
            this.multipleSelection = val;
        },
        markStatus(status){
            let statusName = divStatusMap[status];
            let reqData = {
                ids:[],
                status
            };
            if(this.multipleSelection == null || this.multipleSelection.length == 0){
                //查询待标记数量
                let pageQuery = this.beforeGetData();
                reqData.pageQuery = pageQuery;
                this.$http.post("/divMoney/countBatchMark",  pageQuery, {body:"json"}).then((data) => {
                    this.$confirm("批量将"+data+"条记录标记为'" + statusName + "'?").then(() => {
                            this.$axios.request({
                                method:"post",
                                url: '/divMoney/markStatus',
                                data:reqData
                            }).then(()=>{
                                this.initData();
                            }).catch(e => {
                                this.$message.error(e.error);
                            });
                    });

                }).catch(e => {
                    this.$message.error(e.error);
                });

            }else{

                    this.multipleSelection.forEach((value) => {
                        reqData.ids.push(value.id);
                    });
                    
                    
                    
                    this.$confirm("是否将选择的"+reqData.ids.length+"条记录标记为'" + statusName + "'?").then(() => {
                        this.$axios.request({
                            method:"post",
                            url: '/divMoney/markStatus',
                            data:reqData
                        }).then(()=>{
                            this.initData();
                        }).catch(e => {
                            this.$message.error(e.error);
                        });

                    });
            }

            

            
            
        },
        calculateMoneyInfo(){
            let reqData = this.beforeGetData();
            this.$axios
                .request({
                    method:"post",
                    url: '/divMoney/calculateMoneyInfo',
                    data: reqData
                })
                .then(res => {
                   this.moneyInfo = res.data || {};
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        },
        executeDivMoney(divMoneyId){
            this.fetchingData = true;
            this.$http
                .post('/divMoney/executeDivMoney', {divMoneyId}, {body:"json"})
                .then(res => {
                    this.fetchingData = false;
                   this.$message.success("操作成功");
                   this.initData();
                })
                .catch(e => {
                    this.fetchingData = false;
                    this.$message.error(e.error);
                });
        },
        batchDivMoney(){
            //查询待处理数量
            let pageQuery = this.beforeGetData();
            this.$http.post("/divMoney/countBatchMark",  pageQuery, {body:"json"}).then((data) => {
                this.$confirm("批量将"+data+"条记录执行分账?").then(() => {
                    this.fetchingData = true;
                    this.$http.post("/divMoney/batchDivMoney", pageQuery, {body:"json"}).then((res)=>{
                        this.fetchingData = false;
                        var msg = res || "操作成功" ;
                        this.$message.success(msg);
                        this.initData();
                    }).catch(e => {
                        this.fetchingData = false;
                        this.$message.error(e.error);
                    });
                });

            }).catch(e => {
                this.$message.error(e.error);
            });

        },
        viewTransferInfo(transferMoneyId){
            this.$http
                .post('/divMoney/viewTransferInfo', {transferMoneyId}, {body:"json"})
                .then(res => {
                    this.transferInfoDialog = true;
                    this.transferInfoData = res || {};
                   
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        },
        retryTransfer(divMoneyId){
            this.transferInfoDialog = false;
            this.executeDivMoney(divMoneyId)
        },
        queryBalance(){
            this.balancePopVisible = false;
            var reqData = {payPlatform:this.balancePayPlatform};
            this.$http
                .post('/divMoney/queryBalance', reqData, {body:"json"})
                .then(res => {
                    this.$alert("账户余额：" + res.balance + ", 可用额度：" + res.creditAmt);
                })
                .catch(e => {
                    this.$message.error(e.error);
                });
        }
        
    }
};
</script>
<style lang="less" scoped>
.tool-wrapper{
    display:block;
    overflow: hidden;
    
    .tool-right{
        float:left;
    }
}

.desc-wrapper{
    padding:0px;
    overflow:hidden;
    .desc-line{
        margin-bottom:10px;
        float:left;
        width:50%;     
        display:flex;
        .desc-label{
            font-weight:800;
            width:140px;
        }

        .desc-label:after{
            content:":";
        }

        .desc-content{
            padding-left:10px;
        }
    }
}
</style>
